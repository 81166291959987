import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  withStyles,
  createStyles,
  Theme,
  Typography,
  Box
} from "@material-ui/core";
import {
  logoTransparent,
  instaIcon,
  twitterIcon,
  linkedinIcon
} from "./assets";
// Customizable Area End

import AccountVerificationController, {
  Props
} from "./AccountVerificationController";

// Customizable Area Start
// Customizable Area End

export class AccountVerification extends AccountVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          className={classes.headerImageContainer}
        >
          <img
            alt="logo"
            src={logoTransparent}
            className={classes.headerLogo}
          />
        </Grid>

        <Grid item xs={12} container className={classes.middleContainer}>
          <Typography align="center" className={classes.cardTitle}>
            Account verification
          </Typography>
          <Typography align="center" className={classes.cardDescription}>
            Hi Leo,
          </Typography>
          <Typography align="center" className={classes.cardDescription}>
            Thank you for signing up! We're excited to have you on board.
            <br />
            To ensure the security of your account and email address, please
            <br />
            verify your email address by clicking on the link below:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.completeButton}
            id="complete-setup-button"
          >
            Complete setup
          </Button>
          <Typography align="center" className={classes.cardDescription}>
            If you didn' t request this email, you can ignore it or <br /> reply
            to this email to get help.
          </Typography>
          <Typography align="center" className={classes.fifthText}>
            The LYNE team
          </Typography>
        </Grid>

        <Grid item xs={12} container className={classes.footerContainer}>
          <Typography align="center" className={classes.followUsText}>
            Follow us on
          </Typography>
          <Box className={classes.socialMediaContainer}>
            <img src={instaIcon} alt="insta" />
            <img src={linkedinIcon} alt="linkedin" />
            <img src={twitterIcon} alt="twitter" />
          </Box>
          <Typography align="center" className={classes.copyrightText}>
            © 2023 LYNE Capital
          </Typography>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const signUpRoleStyles = (theme: Theme) =>
  createStyles({
    headerImageContainer: {
      backgroundColor: "#CDF8FE",
      marginBottom: "2rem"
    },
    headerLogo: {
      width: "10%",
      margin: "0 auto",
      marginTop: "3.5rem",
      marginBottom: "3rem"
    },
    middleContainer: {
      padding: "0 20%",
      paddingTop: "2rem",
      paddingBottom: "4rem",
      flexDirection: "column",
      alignItems: "center"
    },
    footerContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: "2.5rem 0",
      flexDirection: "column",
      alignItems: "center",
      color: "#fff"
    },
    cardTitle: {
      color: "#000",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "1.1rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "2.5rem",
      marginBottom: "1.25rem"
    },
    cardDescription: {
      color: "#1E1E1E",
      fontFamily: "Poppins",
      fontSize: "1.1rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "2rem",
      marginBottom: "2.5rem"
    },
    fifthText: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "2rem"
    },
    completeButton: {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.5rem",
      width: "30%",
      marginBottom: "3rem"
    },
    socialMediaContainer: {
      display: "flex",
      gap: "1.25rem"
    },
    followUsText: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "1.375rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "2.5rem",
      marginBottom: "0.5rem"
    },
    copyrightText: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.75rem",
      marginTop: "1.5rem"
    }
  });

export default withStyles(signUpRoleStyles)(AccountVerification);
// Customizable Area End
