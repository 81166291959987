import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent, RefObject, createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentTab: string;
  activeStep: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];
    this.sectionOneRef = createRef();
    this.sectionTwoRef = createRef();
    this.sectionThreeRef = createRef();
    this.sectionFourRef = createRef();
    this.sectionFiveRef = createRef();

    this.state = {
      currentTab: "investor",
      activeStep: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  sectionOneRef: RefObject<HTMLDivElement>;
  sectionTwoRef: RefObject<HTMLDivElement>;
  sectionThreeRef: RefObject<HTMLDivElement>;
  sectionFourRef: RefObject<HTMLDivElement>;
  sectionFiveRef: RefObject<HTMLDivElement>;

  handleTabChange = (event: ChangeEvent<{}>, newValue: string) => {
    this.setState({
      currentTab: newValue
    });
  };

  handleStepChange = (
    stepNumber: number,
    refName: RefObject<HTMLDivElement>
  ) => {
    this.setState({ activeStep: stepNumber });
    if (refName.current !== null && refName.current !== undefined) {
      refName.current.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  ////////////////////////////////////////
  handlePrivacyPolicy = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PrivacyPolicy");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  goToSingUpRolePage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSignUpRoleMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  // Customizable Area End
}
