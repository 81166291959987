import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Toolbar,
  AppBar,
  Tabs,
  Tab,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  logoSmall,
  logoLarge,
  logoGreybg,
  logoDarkbg,
  fourBoxBG,
  boyWithLaptop,
  starIcon,
  lyneIcon,
  lpImage1,
  lpImage2,
  lpImage3,
  lpImage4,
  lpImage5,
  instaIcon,
  linkedinIcon,
  twitterIcon
} from "./assets";
// Customizable Area End

import LandingPageController, {
  configJSON,
  Props
} from "./LandingPageController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#192B39",
      contrastText: "#fff"
    },
    secondary: {
      main: "#24ADBF"
    },
    text: {
      primary: "#1E1E1E"
    }
  },
  typography: {
    fontFamily: "Poppins",
    htmlFontSize: 14,
    fontSize: 14
  },
  overrides: {
    MuiButton: {
      root: {
        height: "2.8rem",
        borderRadius: "0.5rem",
        padding: "8px 30px",
        fontSize: "1rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        textAlign: "center",
        textTransform: "none"
      }
    }
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#784af4"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#784af4"
    }
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector);

// Customizable Area End

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ height: "fit-content" }}>
          <AppBar
            position="sticky"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <img
                  src={logoSmall}
                  alt="Lyne"
                  className={classes.headerLogo}
                />
                <Box>
                  <Button variant="text" color="primary">
                    Log In
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.goToSingUpRolePage}
                    data-test-id="signUpBtnTest"
                  >
                    Sign Up
                  </Button>
                </Box>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container>
            <Grid item xs={12} className={classes.firstSectionContainer}>
              <Typography className={classes.firstSectionHeading}>
                Unlock Investment Opportunities <br /> with LYNE Capital
              </Typography>
              <Typography className={classes.firstSectionSubHeading}>
                Connecting Startups and Investors for Growth
              </Typography>
              <Button
                color="primary"
                variant="contained"
                className={classes.firstSectionLyneButton}
              >
                Get In {configJSON.LYNE}
              </Button>
              <img
                src={boyWithLaptop}
                alt="laptop_guy"
                className={classes.firstSectionLaptopBoy}
              />
            </Grid>
          </Grid>
          <Box sx={{ height: "fit-content" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.secondSectionContainer}
                container
              >
                <Grid item xs={6}>
                  <Typography className={classes.secondSectionHeading}>
                    About {configJSON.LYNE} Capital
                  </Typography>
                  <Typography className={classes.secondSectionSubHeading}>
                    {configJSON.LYNE} Capital is your gateway to the dynamic world
                    of startup <br />
                    investments. We believe that innovative ideas deserve the{" "}
                    <br /> support they need to thrive. Whether you're a visionary
                    startup <br /> founder or a seasoned investor,{" "}
                    {configJSON.LYNE} Capital empowers you to <br /> connect,
                    collaborate, and grow.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={logoLarge}
                    alt="LYNE"
                    className={classes.secondSectionLogo}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                className={classes.thirdSectionContainer}
              >
                <Grid item xs={12}>
                  <Typography className={classes.thirdSectionHeading}>
                    Discover the advantages of joining the{" "}
                    <span className={classes.primaryColorText}>LYNE</span> <br />
                    Capital community <img src={starIcon} alt="starIcon" />
                  </Typography>
                  <img
                    src={fourBoxBG}
                    alt="bg img"
                    className={classes.thirdBoxBgImg}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                className={classes.fourthSectionContainer}
              >
                <Grid item xs={12} className={classes.tabsContainer}>
                  <Tabs
                    value={this.state.currentTab}
                    onChange={this.handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    id="landingpage-tab"
                  >
                    <Tab
                      label="Investor benefits"
                      value="investor"
                      className={classes.tabStyles}
                    />
                    <Tab
                      label="Startup benefits"
                      value="startup"
                      className={classes.tabStyles}
                    />
                  </Tabs>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  className={classes.fourthSectionContainer}
                >
                  <Grid item xs={12} className={classes.stepperContainer}>
                    <Grid container>
                      <Grid item xs={1}>
                        <Stepper
                          activeStep={this.state.activeStep}
                          orientation="vertical"
                          color="secondary"
                          connector={<QontoConnector />}
                          className={classes.stepperBg}
                        >
                          <Step
                            onClick={this.handleStepChange.bind(
                              this,
                              0,
                              this.sectionOneRef
                            )}
                            color="secondary"
                            id="landingpage-step"
                          >
                            <StepLabel />
                          </Step>
                          <Step
                            onClick={this.handleStepChange.bind(
                              this,
                              1,
                              this.sectionTwoRef
                            )}
                            color="secondary"
                          >
                            <StepLabel />
                          </Step>
                          <Step
                            onClick={this.handleStepChange.bind(
                              this,
                              2,
                              this.sectionThreeRef
                            )}
                            color="secondary"
                          >
                            <StepLabel />
                          </Step>
                          <Step
                            onClick={this.handleStepChange.bind(
                              this,
                              3,
                              this.sectionFourRef
                            )}
                            color="secondary"
                          >
                            <StepLabel />
                          </Step>
                          <Step
                            onClick={this.handleStepChange.bind(
                              this,
                              4,
                              this.sectionFiveRef
                            )}
                            color="secondary"
                          >
                            <StepLabel />
                          </Step>
                        </Stepper>
                      </Grid>
                      <Grid item xs={11} container>
                        <Grid
                          item
                          xs={6}
                          className={classes.fiveFeaturesMargin}
                          ref={this.sectionOneRef}
                        >
                          <Typography
                            className={classes.featuresHeading}
                            color="textPrimary"
                          >
                            Access a diverse <br /> range of investment <br />{" "}
                            opportunities.
                          </Typography>
                          <Typography className={classes.featuresDescription}>
                            LYNE offers you access to a diverse and dynamic
                            portfolio of investment opportunities. From early-stage
                            startups with groundbreaking ideas to established
                            businesses looking to scale, our platform connects you
                            with a broad spectrum of ventures. Diversifying your
                            investment portfolio has never been easier, allowing you
                            to spread risk and seize exciting opportunities across
                            various sectors and stages.
                          </Typography>
                          <Button color="primary" variant="contained">
                            Create your Investor account
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          container
                          justifyContent="center"
                          alignItems="center"
                          className={classes.fiveFeaturesMargin}
                        >
                          <img src={lpImage1} />
                        </Grid>
                        <Grid item xs={12} className={classes.stepperContainer}>
                          <Grid container>
                            <Grid item xs={11} container>

                              <Grid
                                item
                                xs={6}
                                className={classes.fiveFeaturesMargin}
                                ref={this.sectionTwoRef}
                              >
                                <Typography
                                  className={classes.featuresHeading}
                                  color="textPrimary"
                                >
                                  Streamline your due <br /> diligence process.
                                </Typography>
                                <Typography className={classes.featuresDescription}>
                                  We understand the importance of thorough due diligence
                                  when making investment decisions. With LYNE Capital, we
                                  provide you with the tools and resources to streamline
                                  this critical process. Our platform offers in-depth
                                  insights into startups, historical data, and relevant
                                  documentation, helping you make well-informed investment
                                  choices efficiently and confidently.
                                </Typography>
                                <Button color="primary" variant="contained">
                                  Create your Investor account
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.fiveFeaturesMargin}
                              >
                                <img src={lpImage2} />
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                className={classes.fiveFeaturesMargin}
                                ref={this.sectionThreeRef}
                              >
                                <Typography
                                  className={classes.featuresHeading}
                                  color="textPrimary"
                                >
                                  Connect with <br /> promising startup in <br /> the MENA
                                  region and
                                  <br />
                                  beyond.
                                </Typography>
                                <Typography className={classes.featuresDescription}>
                                  LYNE serves as your gateway to promising startups not
                                  only in the MENA region but also on a global scale. Our
                                  extensive network connects you with visionary founders
                                  and innovative ventures from diverse industries and
                                  geographies. Whether you're looking to invest locally or
                                  explore opportunities abroad, we have you covered.
                                </Typography>
                                <Button color="primary" variant="contained">
                                  Create your Investor account
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.fiveFeaturesMargin}
                              >
                                <img src={lpImage3} />
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                className={classes.fiveFeaturesMargin}
                                ref={this.sectionFourRef}
                              >
                                <Typography
                                  className={classes.featuresHeading}
                                  color="textPrimary"
                                >
                                  Standardize your
                                  <br /> investment transactions
                                  <br /> for efficiency.
                                </Typography>
                                <Typography className={classes.featuresDescription}>
                                  Efficiency is at the heart of LYNE. We've designed our
                                  platform to standardize and simplify investment
                                  transactions. From standardized legal agreements to
                                  secure admin support, we ensure that your investments
                                  proceed smoothly and without unnecessary complications,
                                  saving you time and effort.
                                </Typography>
                                <Button color="primary" variant="contained">
                                  Create your Investor account
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.fiveFeaturesMargin}
                              >
                                <img src={lpImage4} />
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                className={classes.fiveFeaturesMargin}
                                ref={this.sectionFiveRef}
                              >
                                <Typography
                                  className={classes.featuresHeading}
                                  color="textPrimary"
                                >
                                  Stay informed with
                                  <br /> legal and compliance
                                  <br /> resources.
                                </Typography>
                                <Typography className={classes.featuresDescription}>
                                  Staying compliant with legal and regulatory requirements
                                  is paramount in the world of investments. LYNE provides
                                  you with access to comprehensive legal and compliance
                                  resources. Stay up-to-date with the latest industry
                                  regulations, compliance guidelines, and best practices
                                  to ensure that your investments are on the right side of
                                  the law, all within our platform.
                                </Typography>
                                <Button color="primary" variant="contained">
                                  Create your Investor account
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.fiveFeaturesMargin}
                              >
                                <img src={lpImage5} />
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              container
                              justifyContent="center"
                              alignItems="center"
                              direction="column"
                              className={classes.bottomSectionContainer}
                            >
                              <img
                                src={logoGreybg}
                                alt="LYNE"
                                className={classes.bottomSectionLogo}
                              />
                              <Typography className={classes.botttomSectionHeading}>
                                Join LYNE Capital Today
                              </Typography>
                              <Typography className={classes.bottomSectionDescription}>
                                Ready to embark on your investment journey or support
                                groundbreaking startups? Join LYNE Capital <br /> and
                                become a part of our vibrant community. Start connecting,
                                collaborating, and growing today.
                              </Typography>
                              <Button color="primary" variant="contained">
                                Create your Investor account
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.footerContainer}>
                <img src={lyneIcon} alt="LYNE" className={classes.footerLogo} />
                <Grid container justifyContent="space-between">
                  <Grid item xs={2}>
                    <img src={logoDarkbg} alt="LYNE" />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.footerHeading}>
                      Have questions?
                    </Typography>
                    <Typography className={classes.footerDescription}>
                      Contace our support team at
                      <br />
                      support@lyne.co
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.footerHeading}>
                      Follow us on
                    </Typography>
                    <Box className={classes.socialMediaContainer}>
                      <img src={instaIcon} alt="insta" />
                      <img src={linkedinIcon} alt="linkedin" />
                      <img src={twitterIcon} alt="twitter" />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      className={classes.footerDescription}
                      onClick={this.handlePrivacyPolicy}
                      data-test-id="privacyPolicyBtnTest"
                    >
                      Privacy Policy
                    </Typography>
                    <Typography className={classes.footerDescription}>
                      Terms and Conditions
                    </Typography>
                    <Typography className={classes.footerDescription}>
                      © 2023 Lyne Capital
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const landingPageStyles = (theme: Theme) =>
  createStyles({
    appBar: {
      width: "100%",
      backgroundColor: " #ffffff",
      padding: "0.5rem 1rem"
    },
    toolbar: {
      flexWrap: "nowrap",
      margin: "0 2rem",
      minHeight: 0,
      padding: "1.5rem 0px"
    },
    headerLogo: {
      marginLeft: "1rem"
    },
    firstSectionContainer: {
      background:
        "linear-gradient(267deg, #25ADBF -20.93%, rgba(37, 173, 191, 0.00) 121.14%)",
      padding: "6rem 8.5rem",
      position: "relative"
    },
    firstSectionHeading: {
      fontSize: "2.5rem",
      fontWeight: 600,
      lineHeight: "50px",
      letterSpacing: "0px",
      marginBottom: "2rem"
    },
    firstSectionSubHeading: {
      fontSize: "1.375rem",
      lineHeight: "2.5rem",
      letterSpacing: "0px",
      marginBottom: "4rem"
    },
    firstSectionLyneButton: {
      padding: "0 4rem"
    },
    firstSectionLaptopBoy: {
      position: "absolute",
      right: 150,
      bottom: -125
    },
    secondSectionContainer: {
      padding: "5rem 8.5rem",
      position: "relative",
      marginTop: "12rem",
      marginBottom: "6rem"
    },
    secondSectionHeading: {
      fontSize: "2.5rem",
      fontWeight: 600,
      lineHeight: "50px",
      letterSpacing: "0px",
      marginBottom: "2rem"
    },
    secondSectionSubHeading: {
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      fontWeight: 400,
      letterSpacing: "0px"
    },
    secondSectionLogo: {
      marginLeft: "10rem"
    },
    thirdSectionContainer: {
      padding: "5rem 8.5rem",
      position: "relative",
      backgroundColor: "#F1F5F9"
    },
    thirdSectionHeading: {
      fontSize: "3.375rem",
      fontWeight: 500,
      letterSpacing: "0px",
      marginBottom: "2rem",
      color: "#2B4E69"
    },
    primaryColorText: {
      color: "#192B39"
    },
    thirdBoxBgImg: {
      position: "absolute",
      left: 0,
      top: "13%"
    },
    fourthSectionContainer: {
      position: "relative",
      backgroundColor: "#F1F5F9"
    },
    tabsContainer: {
      borderBottom: "1px solid #D9D9D9",
      padding: "0 6.5rem"
    },
    tabStyles: {
      fontSize: "2.5rem",
      maxWidth: "none",
      textTransform: "none",
      margin: "0 1.5rem",
      fontWeight: 600,
      lineHeight: "3.125rem",
      paddingBottom: "1.1rem"
    },
    stepperContainer: {
      marginTop: "3rem"
    },
    stepperBg: {
      backgroundColor: "#F1F5F9"
    },
    fiveFeaturesMargin: {
      marginBottom: "15rem"
    },
    featuresHeading: {
      fontSize: "4rem",
      fontWeight: 500,
      marginBottom: "1.44rem"
    },
    featuresDescription: {
      fontSize: "1.25rem",
      fontWeight: 400,
      marginBottom: "2.25rem"
    },
    bottomSectionContainer: {
      margin: "8rem",
      marginBottom: "10rem"
    },
    bottomSectionLogo: {
      marginBottom: "4.18rem"
    },
    botttomSectionHeading: {
      fontSize: "3.375rem",
      fontWeight: 600,
      marginBottom: "2.13rem"
    },
    bottomSectionDescription: {
      fontSize: "1.25rem",
      fontWeight: 400,
      marginBottom: "2.13rem"
    },
    footerContainer: {
      position: "relative",
      backgroundColor: "#192B39",
      padding: "6rem",
      paddingBottom: "7rem"
    },
    footerLogo: {
      position: "absolute",
      right: "3rem",
      bottom: "3rem"
    },
    footerHeading: {
      fontSize: "1.375rem",
      lineHeight: "2.5rem",
      fontWeight: 600,
      color: "white",
      marginBottom: "0.5rem"
    },
    footerDescription: {
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      fontWeight: 400,
      color: "white",
      marginBottom: "0.5rem",
      cursor: "pointer"
    },
    socialMediaContainer: {
      display: "flex",
      gap: "1.25rem"
    }
  });

export default withStyles(landingPageStyles)(LandingPage);
// Customizable Area End
