export const logoSmall = require("../assets/image_logo_small.png");
export const logoLarge = require("../assets/image_logo_large.png");
export const logoDarkbg = require("../assets/image_logo_darkbg.png");
export const logoGreybg = require("../assets/image_logo_greybg.png");
export const boyWithLaptop = require("../assets/image_hero.png");
export const fourBoxBG = require("../assets/skip_Union.png");
export const starIcon = require("../assets/skip_Subtract.png");
export const lyneIcon = require("../assets/lyne_icon.png");
export const lpImage1 = require("../assets/lp_image_1.png");
export const lpImage2 = require("../assets/lp_image_2.png");
export const lpImage3 = require("../assets/lp_image_3.png");
export const lpImage4 = require("../assets/lp_image_4.png");
export const lpImage5 = require("../assets/lp_image_5.png");
export const instaIcon = require("../assets/image_instagram.png");
export const linkedinIcon = require("../assets/image_linkedin.png");
export const twitterIcon = require("../assets/image_twitter.png");
