import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  withStyles,
  createStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { setupCompletedHeader } from "./assets";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// Customizable Area End

import AccountSetupCompletedController, {
  Props
} from "./AccountSetupCompletedController";

// Customizable Area Start
// Customizable Area End

export class AccountSetupCompleted extends AccountSetupCompletedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={11} md={5} className={classes.verifyCard}>
          <Grid container>
            <Grid item xs={12} className={classes.headerImageGrid}>
              <img
                alt="logo"
                src={setupCompletedHeader}
                className={classes.headerImage}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              className={classes.secondContainer}
            >
              <Typography className={classes.cardHeadingSetupPage}>
                Account setup completed
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.letsGetStartedButton}
                endIcon={<ArrowForwardIcon />}
                id="lets-get-started-button"
              >
                Let's get started
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const signUpRoleStyles = (theme: Theme) =>
  createStyles({
    verifyCard: {
      margin: "5rem 1.5%",
      borderRadius: "0.625rem",
      border: "1px solid #ABABAB",
      flexDirection: "column",
      overflow: "hidden"
    },
    headerImageGrid: {
      backgroundColor: "rgba(35, 173, 190, 0.1)",
      borderBottomRightRadius: "2rem",
      marginBottom: "2rem",
      overflow: "hidden"
    },
    headerImage: {
      width: "100%"
    },
    secondContainer: {
      padding: "0 3.5rem",
      flexDirection: "column",
      alignItems: "center"
    },
    cardHeadingSetupPage: {
      color: theme.palette.common.black,
      fontFamily: "Poppins",
      fontSize: "2.1rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: "1.7rem"
    },
    letsGetStartedButton: {
      color: "#fff",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
      marginBottom: "3.3rem"
    }
  });

export default withStyles(signUpRoleStyles)(AccountSetupCompleted);
// Customizable Area End
