import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  withStyles,
  createStyles,
  Theme,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  CircularProgress,
  Backdrop,
  Snackbar
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { checkActive, checkUnactive } from "./assets";
import Alert from "@material-ui/lab/Alert";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

// Customizable Area Start
const BEGININNG_SPACE = /^\s+/g;
const NUMBERS = /\d/g;
const SPACE_REGEX = /\s/g;

export const nameMask = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  let value = e.target.value;
  value = value.trim();
  value = value.replace(NUMBERS, "");
  value = value.replace(/[^a-zA-Z ]/g, "");
  value = value.charAt(0).toUpperCase() + value.slice(1);
  e.target.value = value;
  return e;
};

export const spaceMask = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  let value = e.target.value;
  value = value.replace(BEGININNG_SPACE, "");
  e.target.value = value;
  return e;
};

export const noSpaceMask = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  let value = e.target.value;
  value = value.replace(BEGININNG_SPACE, "");
  value = value.replace(SPACE_REGEX, "");
  e.target.value = value;
  return e;
};

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email")
    // .matches(/^[a-zA-Z)-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Invalid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be atleast 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, and one number"
    )
    .required("Password is required"),
  agreeTermsAndConditions: yup
    .boolean()
    .oneOf([true], "Accept terms and conditions to continue.")
    .required("Accept terms and conditions to continue.")
});

// Customizable Area End

export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          agreeTermsAndConditions: false
        }}
        onSubmit={this.handleSignUp}
        validationSchema={validationSchema}
        // onSubmit={this.handleSignUp}
        data-test-id="sign-up-button-type-submit-form"
      >
        {formik => {
          return (
            <Form
              autoComplete="off"
              noValidate
              translate="no"
              // id="sign-up-button-type-submit-form"
            >
              <Snackbar
                open={this.state.showSnackbar}
                autoHideDuration={6000}
                onClose={this.closeSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert severity="error">
                  The account has already been activated with this Email. Please
                  try with another Email.
                </Alert>
              </Snackbar>

              {this.state.loading && (
                <Backdrop
                  className={classes.backdropStyles}
                  open={this.state.loading}
                >
                  <div>
                    <CircularProgress size="5rem" color="primary" />
                  </div>
                </Backdrop>
              )}
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={11}
                  md={3}
                  container
                  justifyContent="center"
                  className={classes.mainContainer}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.formHeader}>
                      Sign up to LYNE
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button className={classes.btnLinedIn}>
                      <span>
                        <img className={classes.linkedInImage} />
                      </span>{" "}
                      Continue with LinkedIn
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.hrContainer}>
                      <div className={classes.hr} />
                      <Typography className={classes.hrText}>OR</Typography>
                      <div className={classes.hr} />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className={classes.fieldLabel}>
                      First Name
                    </Typography>
                    <TextField
                      fullWidth
                      id="first-name-field"
                      name="first_name"
                      placeholder="Enter first name"
                      variant="outlined"
                      value={formik.values.first_name}
                      onChange={e => formik.handleChange(nameMask(e))}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                      className={classes.inputFieldClass}
                    />
                    <Typography className={classes.fieldLabel}>
                      Last Name
                    </Typography>
                    <TextField
                      fullWidth
                      id="last-name-field"
                      name="last_name"
                      placeholder="Enter last name"
                      variant="outlined"
                      value={formik.values.last_name}
                      onChange={e => formik.handleChange(nameMask(e))}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helperText={
                        formik.touched.last_name && formik.errors.last_name
                      }
                      className={classes.inputFieldClass}
                    />
                    <Typography className={classes.fieldLabel}>
                      Email
                    </Typography>
                    <TextField
                      fullWidth
                      id="email-field"
                      name="email"
                      placeholder="Enter email"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={e => formik.handleChange(noSpaceMask(e))}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      className={classes.inputFieldClass}
                    />
                    <Typography className={classes.fieldLabel}>
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      id="password-field"
                      name="password"
                      placeholder="Enter password"
                      variant="outlined"
                      type={this.state.showPassword ? "text" : "password"}
                      value={formik.values.password}
                      onChange={e => formik.handleChange(noSpaceMask(e))}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handlePasswordToggle}
                              data-test-id="password-visibility"
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <VisibilityOffIcon
                                  className={classes.visibilityIcon}
                                />
                              ) : (
                                <VisibilityIcon
                                  className={classes.visibilityIcon}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      className={classes.inputFieldClass}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.passwordValidationsContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      className={classes.passwordValidationsGrid}
                    >
                      <img
                        src={
                          formik.values.password.length >= 8
                            ? checkActive
                            : checkUnactive
                        }
                        alt="checkmark"
                      />
                      <Typography>At least 8 character in length</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      className={classes.passwordValidationsGrid}
                    >
                      <img
                        src={
                          /[a-z]/.test(formik.values.password)
                            ? checkActive
                            : checkUnactive
                        }
                        alt="checkmark"
                      />
                      <Typography>One lower case (a-z)</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      className={classes.passwordValidationsGrid}
                    >
                      <img
                        src={
                          /[A-Z]/.test(formik.values.password)
                            ? checkActive
                            : checkUnactive
                        }
                        alt="checkmark"
                      />
                      <Typography>One upper case (A-Z)</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      className={classes.passwordValidationsGrid}
                    >
                      <img
                        src={
                          /\d/.test(formik.values.password)
                            ? checkActive
                            : checkUnactive
                        }
                        alt="checkmark"
                      />
                      <Typography>One number (0-9)</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container alignItems="flex-start">
                    <Checkbox
                      name="agreeTermsAndConditions"
                      checked={formik.values.agreeTermsAndConditions}
                      onChange={formik.handleChange}
                      color="primary"
                      classes={{
                        root: classes.checkboxRoot
                      }}
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      id="agree-checkbox"
                    />
                    <Typography
                      display="inline"
                      className={classes.checkboxLabel}
                    >
                      I agree with <span>Privacy Policy</span> and{" "}
                      <span onClick={this.goToTermsAndCondition}>
                        Terms and Conditions
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.signUpButton}
                      disabled={!formik.isValid}
                      id="sign-up-button-type-submit"
                    >
                      Sign Up
                    </Button>
                    <Typography className={classes.loginText}>
                      Already have an account?{" "}
                      <span id="goto-login-button" onClick={this.goToLoginPage}>
                        Log in
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const singUpPageStyles = (theme: Theme) =>
  createStyles({
    appBar: {
      width: "100%",
      backgroundColor: " #ffffff",
      borderBottom: "1px solid #e6e6e6"
    },
    toolbar: {
      flexWrap: "nowrap",
      margin: "0 2rem",
      minHeight: 0,
      padding: "1.5rem 0px"
    },
    headerLogo: {
      marginLeft: "1rem"
    },
    mainContainer: {
      border: "1px solid #e6e6e6",
      borderRadius: "1.5rem",
      padding: "2rem",
      margin: "5rem 0",
      "@media (max-width: 1024px)": {
        width: "40%"
      },
      "@media (max-width: 768px)": {
        width: "60%"
      },
      "@media (max-width: 480px)": {
        width: "80%"
      }
    },
    formHeader: {
      color: "#000",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "1.5rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "2rem",
      letterSpacing: "-0.0075rem",
      marginBottom: "2.5rem"
    },
    fieldLabel: {
      color: "#334155",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.5rem",
      marginBottom: "0.3rem"
    },
    linkedInImage: {
      verticalAlign: "middle",
      marginRight: "-4.5em",
      marginTop: "0.8em"
    },
    hrContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "2rem 0"
    },
    hr: {
      flex: 1,
      border: "0.5px solid #e6e6e6"
    },
    hrText: {
      margin: "0 1rem",
      color: "#192B39",
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.375rem"
    },
    btnLinedIn: {
      borderRadius: "0.5rem",
      border: "1px solid #CBD5E1",
      background: "#FFF",
      height: "3.5rem",
      padding: "1rem 4.15625rem 1rem 4.59375rem",
      color: "#334155",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.5rem",
      width: "100%"
    },
    visibilityIcon: {
      color: "#9c9c9c",
      cursor: "pointer"
    },
    signUpButton: {
      color: theme.palette.common.white,
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.5rem",
      height: "3.5rem",
      marginTop: "1.6rem",
      marginBottom: "1rem"
    },
    passwordValidationsContainer: {
      marginBottom: "2.4rem"
    },
    passwordValidationsGrid: {
      gap: "0.4rem",
      marginBottom: "0.5rem",
      "& p": {
        color: "#0F172A",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "1.375rem"
      }
    },
    checkboxRoot: {
      marginTop: "4px",
      marginRight: "0.5rem",
      padding: 0
    },
    checkboxLabel: {
      width: "80%",
      color: "#0F172A",
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.375rem",
      "& span": {
        color: "#0042EA",
        fontFamily: "Poppins",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "1.25rem",
        cursor: "pointer"
      }
    },
    loginText: {
      color: "#0F172A",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.375rem",
      "& span": {
        color: "#0042EA",
        fontFamily: "Poppins",
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "1.375rem",
        cursor: "pointer",
        paddingLeft: "0.5rem"
      }
    },
    backdropStyles: {
      zIndex: 99999,
      "& > div": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }
    },
    inputFieldClass: {
      marginBottom: "0.8rem"
    }
  });

export default withStyles(singUpPageStyles)(EmailAccountRegistration);
// Customizable Area End
