import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  withStyles,
  createStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { investorIcon, startupIcon, individualIcon } from "./assets";
// Customizable Area End

import SignUpRoleController, { Props } from "./SignUpRoleController";

// Customizable Area Start
// Customizable Area End

export class SignUpRole extends SignUpRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={11} container md={3} className={classes.threeCards}>
          <img
            alt="logo"
            src={investorIcon}
            className={classes.userTypeIcons}
          />
          <Typography className={classes.cardTitle}>
            I am an Investor
          </Typography>
          <Typography className={classes.cardDescription}>
            I'm looking for startup investment opportunities
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.signUpButton}
            onClick={this.goToSingUpPage.bind(this, "investor")}
            id="signup-role-investor"
          >
            Sign Up
          </Button>
        </Grid>

        <Grid item xs={11} container md={3} className={classes.threeCards}>
          <img alt="logo" src={startupIcon} className={classes.userTypeIcons} />
          <Typography className={classes.cardTitle}>I am a Startup</Typography>
          <Typography className={classes.cardDescription}>
            I have a startup or I'm planning a fundraising campaign
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.signUpButton}
            onClick={this.goToSingUpPage.bind(this, "startup")}
            id="signup-role-startup"
          >
            Sign Up
          </Button>
        </Grid>

        <Grid item xs={11} container md={3} className={classes.threeCards}>
          <img
            alt="logo"
            src={individualIcon}
            className={classes.userTypeIcons}
          />
          <Typography className={classes.cardTitle}>
            I am an Investor
          </Typography>
          <Typography className={classes.cardDescription}>
            I want to browse different startups
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.signUpButton}
            onClick={this.goToSingUpPage.bind(this, "individual")}
            id="signup-role-individual"
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const signUpRoleStyles = (theme: Theme) =>
  createStyles({
    threeCards: {
      margin: "5rem 1.5%",
      borderRadius: "0.625rem",
      border: "1px solid #ABABAB",
      padding: "2rem",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        maxWidth: "90%",
        flexBasis: "90%"
      }
    },
    userTypeIcons: {
      width: "50%",
      margin: "0 auto",
      marginTop: "1rem",
      marginBottom: "2rem"
    },
    cardTitle: {
      color: "#192B39",
      fontFamily: "Poppins",
      fontSize: "1.75rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.75rem",
      marginBottom: "1rem"
    },
    cardDescription: {
      color: "#1E1E1E",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.75rem",
      height: "4rem"
    },
    signUpButton: {
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
      height: "3.5rem",
      marginTop: "1.6rem",
      marginBottom: "1rem"
    }
  });

export default withStyles(signUpRoleStyles)(SignUpRole);
// Customizable Area End
