import React from "react";

// Customizable Area Start
import {
  AppBar, Grid, Toolbar, Box, Typography, Button, Theme, createStyles, withStyles
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const images = require('./assets.tsx')
import moment from "moment";
// Customizable Area End

import PrivacyPolicyController, { Props } from "./PrivacyPolicyController.web";

// Customizable Area Start
const theme = createTheme({
  typography: {
    fontFamily: "Poppins"
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        transition :"none",
      }
    },

  }
});

// Customizable Area End

export class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {responseData} = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div style={{ height: "auto" }}>
          <AppBar
            position="sticky"
            color="default"
            elevation={0}
            className={classes.appBar}
          >
            <Toolbar>
              <Grid container justifyContent="space-between" alignItems="center">
                <img
                  src={images.logoSmall}
                  alt="Lyne" className={classes.headerLogo} />
                <Box>
                  <Button variant="text" className={classes.loginBtn} disabled={true}>
                    Log In
                  </Button>
                  <Button variant="contained" className={classes.SignUpBtn} data-test-id="signInTest">
                    Sign Up
                  </Button>
                </Box>
              </Grid>
            </Toolbar>
          </AppBar>
          <Button className= {classes.backBtn} onClick={this.handleBackBtn} data-test-id ="backBtnTest">
            <img src={images.backLogo}  style={{marginRight:"2%"}}/>
            <Typography style={{fontWeight:600}}>Back</Typography>
          </Button>
          <Box
            className={classes.container}
          >

            <Grid item>
              <Typography className={classes.header}>Privacy Policy</Typography>
            </Grid>
            <Box className={classes.container2}>
              <Grid item><Typography className={classes.label}>Last Updated:{"  "}
                {moment(responseData?.attributes?.updated_at).format("Do MMM,  YYYY")}
                
              </Typography></Grid>
              <Grid item><Typography style={{ marginBottom: "5%" , fontFamily :"Poppins"}}>
               
                <div dangerouslySetInnerHTML={{ __html: responseData?.attributes?.description }} />
              
              </Typography></Grid>
            </Box>
          </Box>
        </div>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = (theme: Theme) => createStyles({
  appBar: {
    width: "100%",
    backgroundColor: " #ffffff",
    borderBottom: "1px solid #e6e6e6",
  },
  toolbar: {
    flexWrap: "nowrap",
    margin: "0 2rem",
    minHeight: 0,
    padding: "1.5rem 0px"
  },
  headerLogo: {
    marginLeft: "1rem"
  },
  loginBtn: {
    color: "black",
    marginRight: "2em",
    "@media (max-width: 768px)": {
      fontSize: '0.8em',
    },

  },
  SignUpBtn: {
    backgroundColor: "black",
    color: "white",
  },
  container: {
    height: "auto",
    marginTop: "2%",
    marginBottom: "5%",
    marginLeft: "5%",
    marginRight: "5%",
    border: "1px solid #e6e6e6",
    borderRadius: "15px",

    "@media (max-width: 1024px)": {
      width: "40%",
    },
    "@media (max-width: 768px)": {
      width: "60%",
    },
    "@media (max-width: 480px)": {
      width: "80%",
    },


  },
  container2: {
    boxSizing: "border-box",
    padding: "4%",
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
  },
  header: {
    boxSizing: "border-box",
    padding: "2%", color: "white",
    backgroundColor: "black",
    textAlign: "center",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    fontSize: "25px",
    fontWeight: 700,
  },
  label: {
    marginBottom: "2%",
    fontWeight: 600
  },
  backBtn :{
    display:"flex",
    marginTop:"2%",
    marginLeft:"5%",
    cursor:"pointer",

  }
})
export default withStyles(webStyle)(PrivacyPolicy);
// Customizable Area End