// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { ThemeProvider, CssBaseline, withStyles, createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: "#192B39",
      contrastText: "#fff"
    },
    secondary: {
      main: "#24ADBF"
    },
    text: {
      primary: "#1E1E1E"
    }
  },
  typography: {
    fontFamily: "Poppins",
    htmlFontSize: 16,
  },
  overrides: {
    MuiButton: {
      root: {
        height: "2.8rem",
        borderRadius: "0.5rem",
        padding: "8px 30px",
        fontSize: "1rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        textAlign: "center",
        textTransform: "none"
      }
    }
  }
});

const styles = theme => ({
  '@global': {
    html: {
      fontSize: 10,
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 10
      },
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: 11.5
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: 14
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 16
      }
    }
  }
});

const App1 = withStyles(styles)(App);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <App1 />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();
