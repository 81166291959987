// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Cfcontractgeneration4 from "../../blocks/cfcontractgeneration4/src/Cfcontractgeneration4";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
// import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import SignUpRole from "../../blocks/email-account-registration/src/SignUpRole.web";
import VerifyYourEmail from "../../blocks/email-account-registration/src/VerifyYourEmail.web";
import AccountVerification from "../../blocks/email-account-registration/src/AccountVerification.web";
import AccountSetupCompleted from "../../blocks/email-account-registration/src/AccountSetupCompleted.web";
import Cffundraisingbystartup1 from "../../blocks/cffundraisingbystartup1/src/Cffundraisingbystartup1";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfanalyticsagainsttheprofile from "../../blocks/cfanalyticsagainsttheprofile/src/Cfanalyticsagainsttheprofile";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
// import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Cfinvestortorequesttostartupdataroom1 from "../../blocks/cfinvestortorequesttostartupdataroom1/src/Cfinvestortorequesttostartupdataroom1";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Cfusercanaddthemselvesinthestartupaccount1 from "../../blocks/cfusercanaddthemselvesinthestartupaccount1/src/Cfusercanaddthemselvesinthestartupaccount1";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web"


const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
Cfcontractgeneration4:{
 component:Cfcontractgeneration4,
path:"/Cfcontractgeneration4"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
SignUpRole:{
  component: SignUpRole,
path: "/SignUpRole"
},
VerifyYourEmail:{
  component: VerifyYourEmail,
path: "/VerifyYourEmail"
},
AccountVerification:{
  component: AccountVerification,
path: "/AccountVerification"
},
AccountSetupCompleted:{
  component: AccountSetupCompleted,
path: "/AccountSetupCompleted"
},
Cffundraisingbystartup1:{
 component:Cffundraisingbystartup1,
path:"/Cffundraisingbystartup1"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfanalyticsagainsttheprofile:{
 component:Cfanalyticsagainsttheprofile,
path:"/Cfanalyticsagainsttheprofile"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Cfinvestortorequesttostartupdataroom1:{
 component:Cfinvestortorequesttostartupdataroom1,
path:"/Cfinvestortorequesttostartupdataroom1"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Cfusercanaddthemselvesinthestartupaccount1:{
 component:Cfusercanaddthemselvesinthestartupaccount1,
path:"/Cfusercanaddthemselvesinthestartupaccount1"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  PrivacyPolicy :{
    component: PrivacyPolicy,
    path: "/PrivacyPolicy",
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
