import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userEmail: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VerifyYourEmailController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      userEmail: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const userEmail = message.getData(
        getName(MessageEnum.NavigationStatePayload)
      );
      await StorageProvider.set("userEmail", userEmail);
      this.setState({
        userEmail: userEmail
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const userEmail = await StorageProvider.get("userEmail");
    this.setState({
      userEmail: userEmail
    });
  }
  // Customizable Area End
}
