import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseData: any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  privacyApiCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      responseData: {},

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.handlePolicy()
  }


  handlePolicy = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.privacyApiCall = requestMessageAPi.messageId;
    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
      ),
      configJSON.privacyAPiEndPointURL
    );


    requestMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessageAPi.id, requestMessageAPi);
  }

  handleBackBtn = ()=> {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  // Customizable Area End

  async receive(from: string, message: Message) {
      // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (requestCallId != null) {
        if (requestCallId === this.privacyApiCall) {
          this.setState({ responseData: response.data });
          
        }
      }
        // Customizable Area End
    }
  }
}
