export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const checkActive = require("../assets/check_active.png");
export const checkUnactive = require("../assets/check_unactive.png");
export const investorIcon = require("../assets/investor_icon.png");
export const startupIcon = require("../assets/startup_icon.png");
export const individualIcon = require("../assets/individual_icon.png");
export const verifyEmailHeader = require("../assets/verify_email_header.png");
export const logoTransparent = require("../assets/logo_transparent.png");
export const instaIcon = require("../assets/image_instagram.png");
export const linkedinIcon = require("../assets/image_linkedin.png");
export const twitterIcon = require("../assets/image_twitter.png");
export const setupCompletedHeader = require("../assets/setup_completed_header.png");
