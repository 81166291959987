import React from "react";

// Customizable Area Start
import {
  Button,
  Grid,
  withStyles,
  createStyles,
  Theme,
  Typography
} from "@material-ui/core";
import { verifyEmailHeader } from "./assets";
// Customizable Area End

import VerifyYourEmailController, { Props } from "./VerifyYourEmailController";

// Customizable Area Start
// Customizable Area End

export class VerifyYourEmail extends VerifyYourEmailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={11} md={5} className={classes.verifyCard}>
          <Grid container>
            <Grid item xs={12} className={classes.headerImageGrid}>
              <img
                alt="logo"
                src={verifyEmailHeader}
                className={classes.headerImage}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              className={classes.secondContainer}
            >
              <Typography className={classes.cardTitle}>
                Verify your email
              </Typography>
              <Typography className={classes.cardDescription}>
                We've sent an email to{" "}
                <span>{this.state.userEmail || "NA"}</span> to verify your email
                address and activate your account. The link in the email will
                expire in 24 hours.
              </Typography>
              <Typography className={classes.bottomText}>
                If you did not receive this email check your spam filter, <br />{" "}
                or <span>click here</span> to resend it.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.signUpButton}
                id="verify-your-email-page-login-button"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const signUpRoleStyles = (theme: Theme) =>
  createStyles({
    verifyCard: {
      margin: "5rem 1.5%",
      borderRadius: "0.625rem",
      border: "1px solid #ABABAB",
      flexDirection: "column",
      overflow: "hidden"
    },
    headerImageGrid: {
      backgroundColor: theme.palette.primary.main,
      borderBottomRightRadius: "2rem",
      marginBottom: "2rem",
      overflow: "hidden"
    },
    headerImage: {
      width: "100%"
    },
    secondContainer: {
      padding: "0 3.5rem",
      flexDirection: "column",
      alignItems: "center"
    },
    cardTitle: {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "2.1rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: "1.7rem"
    },
    cardDescription: {
      color: "#1E1E1E",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginBottom: "2.3rem",
      "& span": {
        color: "#038D9E",
        fontFamily: "Poppins",
        fontSize: "1rem",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        textDecorationLine: "underline"
      }
    },
    bottomText: {
      color: "#1E1E1E",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "0.9rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginBottom: "2.38rem",
      "& span": {
        color: "#038D9E",
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "0.9rem",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        cursor: "pointer"
      }
    },
    signUpButton: {
      color: "#fff",
      fontFamily: "Poppins",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "1.5rem",
      marginBottom: "3.3rem",
      width: "30%"
    }
  });

export default withStyles(signUpRoleStyles)(VerifyYourEmail);
// Customizable Area End
